/* Used in all HTML files. */

html {
  cursor: url('/img/cursor-bee-default.png'), auto;
}

a, button {
  cursor: url('/img/cursor-bee-pointer.png'), auto;
}

body {
  font-family: "Shantell Sans", "Comic Sans MS", fantasy, sans-serif;

  max-width: 50rem; /* 800px */
  padding: 0 0.5rem; /* Horizontal padding on mobile. */
  margin: auto;
}
